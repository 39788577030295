import React from 'react';
import Img from 'gatsby-image';
import { graphql, Link, useStaticQuery } from 'gatsby';
// styles
import styles from './type-fitness-app.module.css';

const Routes = require('../../../routes').Routes;

const TypeFitnessApp = () => {
  const data = useStaticQuery(graphql`
    query {
      img1: file(
        relativePath: {
          eq: "landing-pages/mobile-solution-version-b/TypeFitnessAppSection/img-1.png"
        }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img2: file(
        relativePath: {
          eq: "landing-pages/mobile-solution-version-b/TypeFitnessAppSection/img-2.png"
        }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img3: file(
        relativePath: {
          eq: "landing-pages/mobile-solution-version-b/TypeFitnessAppSection/img-3.png"
        }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img4: file(
        relativePath: {
          eq: "landing-pages/mobile-solution-version-b/TypeFitnessAppSection/img-4.png"
        }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img5: file(
        relativePath: {
          eq: "landing-pages/mobile-solution-version-b/TypeFitnessAppSection/img-5.png"
        }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img6: file(
        relativePath: {
          eq: "landing-pages/mobile-solution-version-b/TypeFitnessAppSection/img-6.png"
        }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const renderData = [
    {
      img: data.img1.childImageSharp.fluid,
      title: 'Workout and exercise apps',
      text: 'Roll out personal trainer apps, logbook apps, and workout fitness apps with device sync. Help users to stay on top of their workout, perform exercises correctly, and track progress.',
      imgWidth: '45%',
    },
    {
      img: data.img2.childImageSharp.fluid,
      title: 'Nutrition apps',
      text: 'Allow users to count calories and macronutrients and find recipes to hit their healthy nutrition goals.',
      imgWidth: '35%',
    },
    {
      img: data.img3.childImageSharp.fluid,
      title: 'Activity tracking apps',
      text: 'Integrate activity trackers (e.g., phones, wearables like fitness bands and smartwatches, etc.) to run reports and analyze data.',
      imgWidth: '55%',
    },
    {
      img: data.img4.childImageSharp.fluid,
      title: 'Applications for fitness clubs',
      text: 'The app can serve as a pass, attendance record, communication platform, virtual trainer in the club, among other functions.',
      link: { label: 'Learn more', to: Routes.FITNESS_CENTER_APP },
      imgWidth: '55%',
    },
    {
      img: data.img5.childImageSharp.fluid,
      title: 'Smart equipment apps',
      text: 'Monitor user activity with minimal entry actions - for example, use NFC technology on the fitness machine to record reps, sets, weight used, rest periods, etc.',
      imgWidth: '35%',
    },
    {
      img: data.img6.childImageSharp.fluid,
      title: 'Apps for disease management',
      text: 'Give the ability to manage and improve wellbeing, e.g., integrate exercise data with data on blood sugar and food intake for people with diabetes.',
      imgWidth: '40%',
    },
  ];

  return (
    <section className="inner">
      <h2 className="title">Explore apps we built for the fitness industry</h2>
      <p className="subtitle">
        Get your custom digital solution that will match your business needs perfectly
      </p>
      <div className={styles.itemsContainer}>
        {renderData.map((item, i) => (
          <div className={styles.item} key={i}>
            <div className={styles.imgContainer} style={{ width: item.imgWidth ?? '50%' }}>
              <Img fluid={item.img} alt="" />
            </div>
            <div className={styles.content}>
              <h3 className={styles.title}>{item.title}</h3>
              <p className={styles.text}>{item.text}</p>
              {!!item.link && (
                <Link to={item.link.to} className={styles.link}>
                  {item.link.label}
                </Link>
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default TypeFitnessApp;
