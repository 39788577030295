import { graphql, useStaticQuery } from 'gatsby';

export const usePageData = () => {
  const data = useStaticQuery(graphql`
    query {
      bg: file(relativePath: { eq: "landing-pages/mobile-solution-version-b/header/bg.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1440) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img: file(relativePath: { eq: "landing-pages/mobile-solution-version-b/header/img.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1440) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return {
    header: {
      title: 'Fitness App Development Services',
      text: 'Let’s discover new points of differentiating your business! Brocoders will craft a health and wellness solution for a seamless yet meaningful experience for users. Activity tracking, data collection, health metrics prediction, and everything else that will make your customers’ fitness journey easier.',
      btnText: 'Get A Quote',
      fitnessAppCaclBtn: true,
      bgImg: data.bg.childImageSharp.fluid,
      img: data.img.childImageSharp.fluid,
    },
    workProcess: {
      title: 'How we work',
      tabs: [
        {
          title: 'Free Consultation',
        },
        {
          title: 'Discovery Phase',
        },
        {
          title: 'Integration',
        },
        {
          title: 'Testing',
        },
        {
          title: 'Launch',
        },
        {
          title: 'Support',
        },
      ],
      tabsData: [
        {
          title: "We'll go over:",
          list: [
            'Preliminary information on your app and business',
            'Which service is ideal for your application',
            'Who your point-of-contact will be',
            'How long the integration will take',
          ],
        },
        {
          title: "We'll conduct:",
          list: [
            'Research related to your app and business',
            'An analysis of your competitors and target audience',
            "An analysis of whether your app meets the API's requirements",
          ],
        },
        {
          title: 'During integration:',
          list: [
            'Software engineers and the project manager will get to work',
            "We'll complete the development cycle",
          ],
        },
        {
          title: "We want to make sure that the integration went smoothly! We'll:",
          list: [
            'Test interactions',
            "Ensure code compliance with the API's requirements",
            'Check performance, security, and functionality',
          ],
        },
        {
          title: 'Almost done! We will:',
          list: ['Upload the app to the app store(s)', 'Ensure the approval'],
        },
        {
          title: "It doesn't end there; after the app launches, we will:",
          list: ['Provide ongoing support', 'Conduct maintenance'],
        },
      ],
    },
    faq: [
      {
        question: 'Is this a custom solution for my app?',
        answer:
          'Yes. Our team can provide any kind of solution - from analyzing requirements to launching and supporting an application.',
        id: 1,
      },
      {
        question: 'Should my app already exist, or could it be a prototype?',
        answer:
          'Your app can be at any stage of development. Our team has extensive experience in fitness software development.',
        id: 2,
      },
      {
        question: 'Will there be additional fees for using services like GoogleFit, Strava, etc.?',
        answer:
          'Yes, there will be. But the fees are worth it; you will get an additional advertising channel and greater customer loyalty.',
        id: 3,
      },
      {
        question: "Can my app collect data from clients' fitness trackers on its own?",
        answer: 'Brocoders can sync your app to almost any list of devices.',
        id: 4,
      },
    ],
  };
};
