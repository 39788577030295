import React from 'react';
import Img from 'gatsby-image';
import { graphql, Link, useStaticQuery } from 'gatsby';
// assets
import userProfile from './assets/user-profile.svg';
import health from './assets/health.svg';
import activity from './assets/activity.svg';
import planners from './assets/planners.svg';
// styles
import styles from './features.module.css';

const renderData = [
  {
    icon: userProfile,
    title: 'User profiles',
    text: 'Unique insights into the customer’s wellbeing: skill level, objectives, medical history, and possible risks.',
  },
  {
    icon: health,
    title: 'Integration with Health/Fitness Data providers',
    text: 'A hub bringing together official and informal health and data silos for advanced user experience.',
    link: { label: 'Learn more', to: '/fitness-app-api-integrations/' },
  },
  {
    icon: activity,
    title: 'Activity Tracking',
    text: 'Easy-to-use tool to sync all workouts and day-to-day activities for personalized analytics.',
  },
  {
    icon: planners,
    title: 'Planners',
    text: 'Setting goals and managing them, creating routines, recording progress, and most importantly, being consistent.',
  },
];

const Features = () => {
  const data = useStaticQuery(graphql`
    query {
      img1: file(
        relativePath: { eq: "landing-pages/mobile-solution-version-b/features/img-1.png" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <section className="inner">
      <h2 className="title">Fitness app features</h2>
      <p className="subtitle">
        We have experience in building custom and complex features to enrich your fitness product
      </p>
      <div className={styles.container}>
        {renderData.map((item, i) => (
          <div className={styles.card} key={i}>
            <div className={styles.icon}>
              <img src={item.icon} alt={item.title} />
            </div>
            <div className={styles.content}>
              <h3 className={styles.title}>{item.title}</h3>
              <p className={styles.text}>{item.text}</p>
              {!!item.link && (
                <Link to={item.link.to} className={styles.link}>
                  {item.link.label}
                </Link>
              )}
            </div>
          </div>
        ))}
        <Img fluid={data.img1.childImageSharp.fluid} className={styles.img} alt="" />
      </div>
    </section>
  );
};

export default Features;
